"use client";

import { localizedFormatter } from "@projectluna/lib/intl/helpers";

import { useGetMarket } from "@/lib/market/client";

export const useLocalizedFormatter = () => {
  const market = useGetMarket();

  return localizedFormatter(market.language.locale);
};
