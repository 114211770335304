import {
  type CountryCode,
  type LanguageCodeEnum,
} from "@projectluna/codegen/schema";

/**
 * Defines supported locales.
 * https://simplelocalize.io/data/locales/
 */
export const SUPPORTED_LOCALES = ["pl-PL", "en-US"] as const;
export type LocaleCode = (typeof SUPPORTED_LOCALES)[number];
export const DEFAULT_LOCALE: LocaleCode = "pl-PL";

/**
 * Defines supported regions.
 */
export const SUPPORTED_REGIONS = ["pl", "en"] as const;
export type RegionId = (typeof SUPPORTED_REGIONS)[number];

/**
 * Defines supported markets.
 * Market id is a combination of region and locale.
 */
export const SUPPORTED_MARKETS = ["pl~pl-PL", "pl~en-US"] as const;
export type MarketId = (typeof SUPPORTED_MARKETS)[number];
export const DEFAULT_MARKET_ID: MarketId = "pl~pl-PL";

/**
 * Defines supported locales.
 */
export const SUPPORTED_CURRENCIES = ["PLN"] as const;
export type CurrencyCode = (typeof SUPPORTED_CURRENCIES)[number];

export type Language = {
  code: LanguageCodeEnum;
  locale: LocaleCode;
  name: string;
  slug: string;
};

export type Market<Matched extends boolean = true> = {
  channelSlug: string;
  countryCode: CountryCode;
  currency: CurrencyCode;
  name: string;
  regionId: RegionId;
  /**
   * When matched - slug is a combination of `[market]-[language]` slugs.
   * When language and market slug is the same - language slug is omitted.
   */
  slug: string;
} & (Matched extends true
  ? {
      id: MarketId;
      language: Language;
    }
  : { languages: Language[] });

export type WithMarket = { market: Market };

SUPPORTED_MARKETS.map((marketId) => {
  const [regionId, localeCode] = marketId.split("~");
  if (!SUPPORTED_LOCALES.includes(localeCode as LocaleCode)) {
    throw new Error(`Unsupported locale ${localeCode}`);
  }

  if (!SUPPORTED_REGIONS.includes(regionId as RegionId)) {
    throw new Error(`Unsupported region ${regionId}`);
  }
});
