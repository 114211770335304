import { PhotoIcon } from "@heroicons/react/24/solid";

import { type WithCssProp, type WithHTMLProp } from "@/lib/types";

import { cn } from "@/styles/lib";

type ImagePlaceholderProps = {
  size?: number;
} & WithHTMLProp<"className" | "style"> &
  WithCssProp<"height" | "width">;

export const ImagePlaceholder = ({
  size = 64,
  className,
  style,
  width,
  height,
}: ImagePlaceholderProps) => (
  <div
    className={cn(
      "relative h-full w-full bg-gray-50 [&>*]:!text-gray-300",
      className
    )}
    style={{ width, height }}
  >
    <div className={cn("absolute-center")}>
      <PhotoIcon style={{ height: size, ...(style as any) }} />
    </div>
  </div>
);
