import {
  DEFAULT_MARKET_ID,
  type Language,
  type Market,
  type MarketId,
} from "./types";

const SUPPORTED_LANGUAGES: Record<string, Language> = {
  PL: {
    name: "Polski",
    code: "PL",
    locale: "pl-PL",
    slug: "pl",
  },
  EN_US: {
    name: "English",
    code: "EN_US",
    locale: "en-US",
    slug: "en",
  },
};

const SUPPORTED_MARKETS: Market<false>[] = [
  {
    channelSlug: "pl",
    countryCode: "PL",
    currency: "PLN",
    languages: [SUPPORTED_LANGUAGES.PL, SUPPORTED_LANGUAGES.EN_US],
    name: "Polska",
    regionId: "pl",
    slug: "pl",
  },
];

const getMarketSlug = (market: Market | Market<false>, language: Language) => {
  if (market.slug === language.slug) {
    return market.slug;
  }
  return `${market.slug}-${language.slug}`;
};

const getMarketId = (market: Market | Market<false>, language: Language) =>
  `${market.regionId}~${language.locale}` as MarketId;

export const MARKETS_SLUG_MAP = SUPPORTED_MARKETS.reduce(
  (acc, market) => {
    market.languages.forEach(language => {
      const slug = getMarketSlug(market, language);
      const id = getMarketId(market, language);
      acc[slug] = { ...market, slug, id, language };
    });

    return acc;
  },
  {} as Record<string, Market>
);

export const MARKETS_ID_MAP = SUPPORTED_MARKETS.reduce(
  (acc, market) => {
    market.languages.forEach(language => {
      const slug = getMarketSlug(market, language);
      const id = getMarketId(market, language);
      acc[id] = { ...market, slug, id, language };
    });

    return acc;
  },
  {} as Record<MarketId, Market>
);

export const MARKETS = Object.values(MARKETS_ID_MAP);

export const DEFAULT_MARKET = MARKETS_ID_MAP[DEFAULT_MARKET_ID];

if (!DEFAULT_MARKET) {
  throw new Error(
    `Market ${DEFAULT_MARKET_ID} is not defined in MARKETS_ID_MAP`
  );
}
